
import Vue, { PropType } from 'vue';
import TTLoader from '@/components/ui/TTLoader.vue';

export interface TableHeaderItem {
  text: string,
  value: string,
  width?: string,
  cellClass?: string,
  class?: string,
}

export interface TableItem {
  id: string,
  name: string,
  value: number,
  color: string,
}

export default Vue.extend({
  name: 'DynamicsDatasetsTable',

  components: {
    TTLoader,
  },

  props: {
    items: {
      type: Array as PropType<TableItem[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    nameColumnTitle: {
      type: String as PropType<string>,
      default: '',
    },
    valueColumnTitle: {
      type: String as PropType<string>,
      default(): string { return this.$t('analytics.feedback.score') as string; },
    },
  },

  computed: {
    headers(): TableHeaderItem[] {
      return [
        {
          text: this.nameColumnTitle,
          value: 'name',
          width: '92%',
          class: 'tt-text-body-2',
          cellClass: 'tt-text-body-1 tt-light-mono-100--text d-flex flex-row align-center py-2 v-align-top',
        },
        {
          text: this.valueColumnTitle,
          value: 'value',
          width: '8%',
          class: 'tt-text-body-2',
          cellClass: 'tt-text-body-2 tt-light-mono-100--text py-2 v-align-top',
        },
      ];
    },
  },
});
