enum LocalStorageItem {
  EMPLOYEES_COMPARISON_PARAMS = 'employeesComparisonParams',
  ANALYTICS_DASHBOARDS = 'analyticsDashboards',
}

// Сохраненные фильтры для страницы Сравнение сотрудников по компетенциям
export interface EmployeesComparisonParams {
  userId: string,
  surveyId: number | null,
  surveyeeIds: string[],
}

// Сохраненные id выбранных опросов для пользователей на странице дашборда
export interface AnalyticsDashboards {
  // Текущий пользователь
  user: UserSurveys,
  // Подчиненные
  surveyees: UserSurveys[],
}

interface UserSurveys {
  userId: string,
  surveys: number[],
}

export default LocalStorageItem;
