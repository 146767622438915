/**
 * Сервис для работы с localStorage
 * Код основан на https://gist.github.com/ulisesantana/8bb0d6b02e675e6fb98480f06c9e0183
 */
const localStorageService = {
  get(itemName: string) {
    const item = localStorage.getItem(itemName);
    const numPatt = new RegExp(/^\d+$/);
    const jsonPatt = new RegExp(/[[{].*[}\]]/);

    if (!item) {
      return null;
    }
    if (jsonPatt.test(item)) {
      return JSON.parse(item);
    }
    if (numPatt.test(item)) {
      return parseFloat(item);
    }
    return item;
  },

  set(itemName: string, item: any) {
    if (typeof item === 'object') {
      localStorage.setItem(itemName, JSON.stringify(item));
    } else {
      localStorage.setItem(itemName, item);
    }
  },

  remove(itemName: string) {
    localStorage.removeItem(itemName);
  },
};

export default localStorageService;
