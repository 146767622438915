
import { defineComponent, PropType } from 'vue';
import TTActionDialog from '@/components/ui/TTDialog/TTActionDialog.vue';
import { V1EntitiesSurveysPublicIndexSurvey } from '@/services/api/tsxass';

export default defineComponent({
  name: 'FeedbackSurveysSelect',

  components: {
    TTActionDialog,
  },

  props: {
    value: {
      type: Array as PropType<V1EntitiesSurveysPublicIndexSurvey[]>,
      default: () => ([]),
    },
    surveys: {
      type: Array as PropType<V1EntitiesSurveysPublicIndexSurvey[]>,
      default: () => ([]),
    },
  },

  data() {
    return {
      isDialogVisible: false,
      internalValue: [] as V1EntitiesSurveysPublicIndexSurvey[],
    };
  },

  computed: {
    listItems() {
      return this.surveys.map((survey) => ({
        value: survey,
        label: this.$t('analytics.feedback.surveyFromDate', {
          name: survey.name,
          date: this.$dayjs(survey.dateStart).format('DD.MM.YYYY'),
        }),
      }));
    },
  },

  methods: {
    resetValue() {
      this.internalValue = [...this.value];
    },
    showDialog() {
      this.resetValue();
      this.isDialogVisible = true;
    },
    onCancel() {
      this.resetValue();
      this.isDialogVisible = false;
    },
    onSubmit() {
      this.$emit('input', this.internalValue);
      this.isDialogVisible = false;
    },
  },
});
