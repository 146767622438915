
import { defineComponent, PropType } from 'vue';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import { V1EntitiesAnalyticsFeedbackOpenQuestionTopCommentsComment } from '@/services/api/tsxass';

export default defineComponent({
  name: 'CommentsFeedCarouselWidget',

  inject: ['RouteNames'],

  props: {
    surveyIds: {
      type: Array as PropType<number[]>,
      default: () => ([]),
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      comments: [] as V1EntitiesAnalyticsFeedbackOpenQuestionTopCommentsComment[],
      loading: false,
      currentComment: 0,
    };
  },

  watch: {
    surveyIds: {
      immediate: true,
      handler() {
        this.loadChartData();
      },
    },
    userId() {
      this.loadChartData();
    },
    comments() {
      this.currentComment = 0;
    },
  },

  methods: {
    async loadChartData() {
      try {
        this.loading = true;
        const { data: { comments } } = await tsxassApi.getV1AnalyticsFeedbackOpenQuestionsTopComments(
          this.surveyIds,
          this.userId || undefined,
        );
        this.comments = comments;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
