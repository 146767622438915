
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { TsxassAbility } from '@/helpers/constants';
import errorHandler from '@/helpers/errorHandler';
import { V1EntitiesSurveysPublicIndexSurvey, V1EntitiesUsersPublicShowUser } from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import PageTitle from '@/components/common/PageTitle.vue';
import NoDataStub from '@/components/common/NoDataStub.vue';
import FeedbackSurveysSelect from '@/components/analytics/feedback/FeedbackSurveysSelect.vue';
import GradeVisualizationWidget from '@/components/analytics/widgets/GradeVisualizationWidget.vue';
import TopResultsWidget from '@/components/analytics/widgets/TopResultsWidget.vue';
import DynamicsWidgetsTabbed from '@/components/analytics/widgets/dashboard/DynamicsWidgetsTabbed.vue';
import CommentsFeedCarouselWidget from '@/components/analytics/widgets/CommentsFeedCarouselWidget.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';
import BackButton from '@/layouts/app/BackButton.vue';
import localStorageService from '@/services/localStorage';
import LocalStorageItem, { AnalyticsDashboards } from '@/constants/localStorageItem';

export default defineComponent({
  name: 'FeedbackFromColleagues',

  components: {
    PageTitle,
    NoDataStub,
    FeedbackSurveysSelect,
    GradeVisualizationWidget,
    TopResultsWidget,
    DynamicsWidgetsTabbed,
    UserAvatar,
    CommentsFeedCarouselWidget,
    BackButton,
  },

  inject: ['RouteNames'],

  data() {
    return {
      surveys: [] as V1EntitiesSurveysPublicIndexSurvey[],
      surveysLoading: false,
      selectedSurveys: [] as V1EntitiesSurveysPublicIndexSurvey[],
      userData: null as null | V1EntitiesUsersPublicShowUser,
    };
  },

  computed: {
    ...mapGetters('engagementInfo', {
      can: 'can',
    }),
    ...mapState('engagementInfo', {
      currentUser: 'user',
    }),

    dataIsEmpty(): boolean {
      return !this.surveys.length;
    },
    latestSurvey(): V1EntitiesSurveysPublicIndexSurvey | null {
      if (!this.selectedSurveys.length) {
        return null;
      }
      const sorted = [...this.selectedSurveys];
      // eslint-disable-next-line arrow-body-style
      sorted.sort((a, b) => {
        return this.$dayjs(a.dateStart).isAfter(this.$dayjs(b.dateStart)) ? 1 : -1;
      });

      return sorted[sorted.length - 1];
    },
    latestSurveyId(): number | null {
      return this.latestSurvey?.id || null;
    },
    dashboardHint(): string {
      if (!this.latestSurvey) {
        return '';
      }

      const date = this.$dayjs(this.latestSurvey.dateStart).format('DD.MM.YYYY');

      return this.$t(
        'analytics.feedback.dashboardHint',
        { name: this.latestSurvey.name, date },
      ) as string;
    },
    userId(): string {
      return (this.can(TsxassAbility.CAN_VIEW_EMPLOYEES_ANALYTICS) && this.$route.query?.userId) || '';
    },
    selectedSurveysIds(): number[] {
      return this.selectedSurveys.map((survey) => survey.id);
    },
  },

  async mounted() {
    if (this.userId) {
      this.loadUserData();
    }
    await this.loadSurveys();
    this.restoreSelectedSurveys();
  },

  methods: {
    async loadSurveys() {
      try {
        this.surveysLoading = true;

        const { data: { surveys } } = await tsxassApi.getV1AnalyticsSurveys(this.userId || undefined, true);
        this.surveys = surveys || [];
      } catch (err) {
        errorHandler(err);
      } finally {
        this.surveysLoading = false;
      }
    },
    async loadUserData() {
      try {
        const { data } = await tsxassApi.getV1UsersId(this.userId);
        this.userData = data?.user || null;
      } catch (err) {
        errorHandler(err);
      }
    },
    // Сохранить данные о выбранных опросах в localStorage
    saveSelectedSurveys() {
      // Максимальное число подчиненных, для которых храним
      const MAX_SAVED_SURVEYEES = 10;
      // Текущие сохраненные данные
      const data: null | AnalyticsDashboards = localStorageService
        .get(LocalStorageItem.ANALYTICS_DASHBOARDS);

      const newData: AnalyticsDashboards = {
        user: {
          userId: this.currentUser.userId,
          surveys: data?.user.surveys || [],
        },
        surveyees: data?.surveyees || [],
      };

      const updatedUserItem = {
        userId: this.userId || this.currentUser.userId,
        surveys: this.selectedSurveysIds.length === this.surveys.length ? [] : this.selectedSurveysIds,
      };

      if (!this.userId) {
        // Обновляем для текущего пользователя
        newData.user = updatedUserItem;
      } else {
        // Обновляем для подчиненного
        const newSurveyees = newData.surveyees.filter((item) => item.userId !== this.userId);
        if (updatedUserItem.surveys.length) {
          newSurveyees.push(updatedUserItem);
        }
        newData.surveyees = newSurveyees.slice(-MAX_SAVED_SURVEYEES);
      }

      localStorageService.set(LocalStorageItem.ANALYTICS_DASHBOARDS, newData);
    },
    getSavedSelectedSurveyIds(): number[] {
      const data: null | AnalyticsDashboards = localStorageService
        .get(LocalStorageItem.ANALYTICS_DASHBOARDS);
      if (!data) {
        return [];
      }
      // Если данные сохранены не для текущего пользователя, очищаем их
      if (data.user.userId !== this.currentUser?.userId) {
        localStorageService.remove(LocalStorageItem.ANALYTICS_DASHBOARDS);
        return [];
      }
      // Если дашборд открыт для текущего пользователя
      if (!this.userId) {
        return data.user.surveys;
      }
      // Если для подчиненных
      return data.surveyees.find((item) => item.userId === this.userId)?.surveys || [];
    },
    // Восстановить список выбранных опросов из сохраненных
    restoreSelectedSurveys() {
      const surveyIds = this.getSavedSelectedSurveyIds();
      if (!surveyIds.length) {
        this.selectedSurveys = [...this.surveys];
        return;
      }
      this.selectedSurveys = this.surveys.filter((survey) => surveyIds.includes(survey.id));
    },
    onSelectedSurveysInput(surveys: V1EntitiesSurveysPublicIndexSurvey[]) {
      this.selectedSurveys = surveys;
      this.saveSelectedSurveys();
    },
  },
});
